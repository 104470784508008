import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import CreateUser from './components/CreateUser';
import ClientFeedbackPage from './ClientFeedbackPage';
import ClientManagement from './components/ClientManagementPage';
import ClientFeedbackFormPage from './components/ClientFeedbackFormPage';
import ComingSoon from './ComingSoon';
import AdminDashboard from './components/AdminDashboard';
import Navbar from './components/Navbar'; // Import the Navbar
import EditAccount from './components/EditAccount';

const AUTO_LOGOUT_TIME = 10 * 60 * 1000; // 10 minutes in milliseconds

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [userId, setUserId] = useState(null); // Add userId state
    const timeoutRef = useRef(null); // Use ref to store timeout ID

    // Check authentication and role on page load
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const role = localStorage.getItem('userRole'); 
        const storedUserId = localStorage.getItem('userId'); // Get the userId from localStorage

        if (token) {
            setIsAuthenticated(true);
            setUserRole(role);
            setUserId(storedUserId); // Set userId from localStorage
        }

        // Start tracking user activity for auto-logout
        startActivityListener();

        // Cleanup event listeners on unmount
        return () => {
            stopActivityListener();
        };
    }, []);

    const handleLogin = (token, userId, role) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('userId', userId);  // Save the userId in localStorage
        localStorage.setItem('userRole', role);  // Save the role in localStorage
        setIsAuthenticated(true);
        setUserRole(role);
        setUserId(userId); // Set the userId in state

        // Reset the inactivity timer on login
        resetInactivityTimeout();
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId'); // Remove userId from localStorage
        localStorage.removeItem('userRole');
        setIsAuthenticated(false);
        setUserRole('');
        setUserId(null); // Clear userId state

        // Clear any existing inactivity timeout
        clearTimeout(timeoutRef.current);
    };

    // Function to reset inactivity timer
    const resetInactivityTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(handleLogout, AUTO_LOGOUT_TIME); // Set auto-logout timer
    };

    // Start listening to user activity
    const startActivityListener = () => {
        window.addEventListener('mousemove', resetInactivityTimeout);
        window.addEventListener('keydown', resetInactivityTimeout);
    };

    // Stop listening to user activity
    const stopActivityListener = () => {
        window.removeEventListener('mousemove', resetInactivityTimeout);
        window.removeEventListener('keydown', resetInactivityTimeout);
    };

    return (
        <Router>
            <AppContent 
                isAuthenticated={isAuthenticated} 
                userRole={userRole} 
                userId={userId} // Pass userId as prop
                onLogout={handleLogout} 
                onLogin={handleLogin} 
            />
        </Router>
    );
}

function AppContent({ isAuthenticated, onLogout, onLogin, userRole, userId }) { // Receive userId as prop
    const location = useLocation();  // Get the current location

    // Define the exact path where the navbar should be hidden
    const hideNavbar = location.pathname.includes('/submit-feedback', '/');

    return (
        <div>
            {/* Conditionally render the Navbar */}
            {isAuthenticated && !hideNavbar && <Navbar isAuthenticated={isAuthenticated} onLogout={onLogout} userRole={userRole} />}
            <Routes>
                <Route 
                    path="/" 
                    element={
                        isAuthenticated ? <Navigate to="/admin/dashboard" /> : <Login onLogin={onLogin} />
                    } 
                />
                <Route path="/create-user" element={<CreateUser />} />
                <Route path="/client/:clientId" element={<ClientFeedbackPage />} />
                <Route path="/clients" element={<ClientManagement />} />
                <Route path="/client/:clientId/feedbacks" element={<ClientFeedbackPage />} />
                <Route path="/client/:clientId/submit-feedback" element={<ClientFeedbackFormPage />} />
                <Route path="/client-insights" element={<ComingSoon />} />
                <Route 
                    path="/edit-account/:type/:id" 
                    element={<EditAccount />} 
                />
                <Route 
                    path="/admin/dashboard" 
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated} element={<AdminDashboard />} />
                    } 
                />
                <Route 
                    path="/dashboard" 
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated} element={<Dashboard role="client" />} />
                    } 
                />
            </Routes>
        </div>
    );
}

// ProtectedRoute Component
function ProtectedRoute({ isAuthenticated, element }) {
    if (!isAuthenticated) {
        // If not authenticated, redirect to login page
        return <Navigate to="/" />;
    }
    // If authenticated, render the passed component
    return element;
}

export default App;
