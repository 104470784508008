import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Remove navigate import since we won't use it
import axios from 'axios';

const EditAccount = () => {
    const { id, type } = useParams(); // Get the ID and type (user or client) from URL parameters
    const [initialData, setInitialData] = useState({}); // Store the initial fetched data
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        name: '',
        description: '',
        logo: null,
        backgroundColor: '#ffffff' // Default background color
    });
    const [message, setMessage] = useState(''); // State to store success or error message
    const [isError, setIsError] = useState(false); // State to differentiate between success and error messages
    
    useEffect(() => {
        // Fetch user or client data based on type and ID
        if (type && id) {
            const url = type === 'user' ? `${process.env.REACT_APP_BACKEND_URL}/api/users.php/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/clients.php/${id}`;
            axios.get(url)
                .then(response => {
                    const data = response.data;
                    setInitialData(data); // Store initial data for comparison
                    if (type === 'user') {
                        setFormData({
                            username: data.username || '',
                            email: data.email || '',
                            password: '', // Always keep password empty initially
                        });
                    } else {
                        setFormData({
                            name: data.name || '',
                            description: data.description || '',
                            logo: null,
                            backgroundColor: data.background_color || '#ffffff' // Set existing color or default
                        });
                    }
                })
                .catch(error => console.error("There was an error fetching the account details!", error));
        }
    }, [id, type]);
    

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'logo') {
            setFormData({
                ...formData,
                logo: files[0] // Handle file upload for logo
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Prepare form data for submission
        const data = new FormData();
        data.append('id', id);
        data.append('type', type);

        // Conditionally add fields only if they have changed from the initial data
        if (type === 'user') {
            if (formData.username && formData.username !== initialData.username) {
                data.append('username', formData.username);
            }
            if (formData.email && formData.email !== initialData.email) {
                data.append('email', formData.email);
            }
            if (formData.password) {  // Only add password if it's filled in
                data.append('password', formData.password);
            }
        } else {
            if (formData.name && formData.name !== initialData.name) {
                data.append('name', formData.name);
            }
            if (formData.description && formData.description !== initialData.description) {
                data.append('description', formData.description);
            }
            if (formData.backgroundColor && formData.backgroundColor !== initialData.background_color) {
                data.append('background_color', formData.backgroundColor);
            }
            if (formData.logo) {  // Only append the logo if it's selected
                data.append('logo', formData.logo);
            }
        }

        // Send POST or PUT request to update user or client details
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/edit-account.php`, data)
            .then(response => {
                // On success, display success message
                setMessage("Account updated successfully!");
                setIsError(false);
            })
            .catch(error => {
                // On error, display error message
                setMessage("There was an error updating the account.");
                setIsError(true);
                console.error("There was an error updating the account!", error);
            });
    };

    return (
        <div className="edit-account-form">
            <h2>Edit {type === 'user' ? 'User' : 'Client'} Account (UNDER CONSTRUCTION)</h2>

            {/* Show success or error message */}
            {message && (
                <div style={{ color: isError ? 'red' : 'green', marginBottom: '20px' }}>
                    {message}
                </div>
            )}

            {/* Show the current data */}
            <div>
                <h3>Current Data:</h3>
                <pre>{JSON.stringify(initialData, null, 2)}</pre> {/* Display the initial data for debugging */}
            </div>

            <form onSubmit={handleSubmit} encType="multipart/form-data">
                {type === 'user' && (
                    <>
                        <label>
                            Username:
                            <input
                                type="text"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label>
                            Password:
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Leave blank to keep current password"
                            />
                        </label>
                        <br />
                    </>
                )}
                {type === 'client' && (
                    <>
                        <label>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label>
                            Description:
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label>
                            Logo:
                            <input
                                type="file"
                                name="logo"
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label>
                            Background Color:
                            <input
                                type="color"
                                name="backgroundColor"
                                value={formData.backgroundColor}
                                onChange={handleChange}
                            />
                        </label>
                    </>
                )}
                <button type="submit">Update</button>
            </form>
        </div>
    );
};

export default EditAccount;
