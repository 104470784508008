import React, { useState } from 'react';
import './FeedbackForm.css';
import { TextField } from '@mui/material';

function FeedbackForm({ onNewFeedback, clientId }) {
    const [text, setText] = useState("");
    const [category, setCategory] = useState("Service");
    const [sentiment, setSentiment] = useState("AI Determined");
    const [rating, setRating] = useState(0);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [showContactFields, setShowContactFields] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false); // Track form submission success
    const [errorMessage, setErrorMessage] = useState(null); // Track form submission error

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFeedback = { text, category, sentiment, rating, client_id: clientId, name, email, phone };

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFeedback),
            });

            if (response.ok) {
                const data = await response.json();
                onNewFeedback(data);

                // Clear form fields after submission
                setText("");
                setCategory("Service");
                setSentiment("AI Determined");
                setRating(0);
                setName("");
                setEmail("");
                setPhone("");
                setShowContactFields(false);

                // Show success message
                setSubmitSuccess(true);
                setErrorMessage(null);

                // Hide success message after 3 seconds
                setTimeout(() => {
                    setSubmitSuccess(false);
                }, 3000);
            } else {
                setSubmitSuccess(false);
                setErrorMessage("Failed to submit feedback.");
            }
        } catch (error) {
            setSubmitSuccess(false);
            setErrorMessage("Error submitting feedback. Please try again.");
            console.error("Error submitting feedback:", error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="right-content">
            <h2>Exit Feedback Form</h2>
            {submitSuccess && <p className="success-message">Thank you for your feedback!</p>}  {/* Display success message */}
            {errorMessage && <p className="success-message">Thank you for your feedback!</p>}  {/* Display error message */}
            
            <TextField
                id="feedback"
                label="Feedback Message:"
                variant="outlined"
                multiline
                value={text}
                onChange={(e) => setText(e.target.value)}
                className='feedback-input'
                InputLabelProps={{
                    style: {
                        color: 'white',  // Make the label white
                    },
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "white", // White border color
                        },
                        "&:hover fieldset": {
                            borderColor: "white", // White border on hover
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "white", // White border when focused
                        },
                    },
                }}
            />

            <div className="toggle-contact-fields">
                <label className="form-options">
                    <input
                        type="checkbox"
                        checked={showContactFields}
                        onChange={() => setShowContactFields(!showContactFields)}
                    />
                    Provide Contact Information (Optional)
                </label>
            </div>

            {showContactFields && (
                <div className="contact-fields">
                    <TextField
                        label="Your Name (Optional)"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "white", // White border color
                                },
                                "&:hover fieldset": {
                                    borderColor: "white", // White border on hover
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "white", // White border when focused
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: 'white',  // Make the label white
                            },
                        }}
                    />
                    <TextField
                        label="Your Email (Optional)"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "white", // White border color
                                },
                                "&:hover fieldset": {
                                    borderColor: "white", // White border on hover
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "white", // White border when focused
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: 'white',  // Make the label white
                            },
                        }}
                    />
                    <TextField
                        label="Your Phone (Optional)"
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "white", // White border color
                                },
                                "&:hover fieldset": {
                                    borderColor: "white", // White border on hover
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "white", // White border when focused
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: 'white',  // Make the label white
                            },
                        }}
                    />
                </div>
            )}

            <button type="submit">Submit Feedback</button>
        </form>
    );
}

export default FeedbackForm;
