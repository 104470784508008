import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import './FeedbackCharts.css'; // Import custom CSS for dark mode

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

function FeedbackCharts({ feedbacks }) {
    const categorizeFeedback = (feedbacks) => {
        const categories = { positive: 0, neutral: 0, negative: 0 };

        feedbacks.forEach(feedback => {
            if (feedback.sentiment === 'Positive') categories.positive++;
            else if (feedback.sentiment === 'Neutral') categories.neutral++;
            else if (feedback.sentiment === 'Negative') categories.negative++;
        });

        return categories;
    };

    const feedbackCategories = categorizeFeedback(feedbacks);

    const barChartData = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [
            {
                label: 'Feedback Count',
                data: [feedbackCategories.positive, feedbackCategories.neutral, feedbackCategories.negative],
                backgroundColor: ['#009A82', '#EBC033', '#C42727'],
                borderWidth: 1,
            },
        ],
    };

    const lineChartData = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [
            {
                label: 'Feedback Count Over Time',
                data: [feedbackCategories.positive, feedbackCategories.neutral, feedbackCategories.negative],
                fill: false,
                backgroundColor: '#009A82',
                borderColor: '#009A82',
                tension: 0.1,
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#fff', // White labels for dark mode
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#fff', // White ticks
                },
                grid: {
                    color: '#555', // Dark grid lines
                },
            },
            y: {
                ticks: {
                    color: '#fff', // White ticks
                },
                grid: {
                    color: '#555', // Dark grid lines
                },
            },
        },
    };

    return (
        <div className="feedback-charts-container">
            <div className="chart-wrapper">
                <div className="chart-item">
                    <Bar data={barChartData} options={chartOptions} />
                </div>
                <div className="chart-item">
                    <Line data={lineChartData} options={chartOptions} />
                </div>
            </div>
        </div>
    );
}

export default FeedbackCharts;
